import { create } from '@storybook/theming';
import logoUrl from '../public/images/brunner-logos/BrunnerLogo.svg';

export default create({
  base: 'light',
  brandTitle: 'Brunner Website Components',
  brandUrl: 'https://brunnerworks.com',
  brandImage: logoUrl,
  brandTarget: '_self',
  fontBase: '"Sarala", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  colorPrimary: '#043C44',
  colorSecondary: '#EF6400',
  barBg: '#ffffff',
  appBg: '#FBF6EE',
  appContentBg: '##ffffff',
  appBorderColor: '#585C6D',
  appBorderRadius: 4,

});